import React, { useState } from "react";
import FormRendererContext from "./FormRendererContext";

const FormRendererContextProvider = ({ children }) => {
  const [journeyData, setJourneyData] = useState(null);
  const [activeScreenID, setActiveScreenID] = useState(null);
  const [activeScreenPageID, setActiveScreenPageID] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [completeJourneyAnswers, setCompleteJourneyAnswers] = useState([]);

  const [schema, setSchema] = useState(null);
  const [activeScreen, setActiveScreen] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [answerData, setAnswerData] = useState({});
  const [journeyId, setJourneyId] = useState(null);

  const [scheduler_id, setScheduler_id] = useState(null);
  const [reason_id, setReason_id] = useState(null);
  const [provider_id, setProvider_id] = useState(null);
  const [appointment_id, setAppointment_id] = useState(null);
  const [patient_id, setPatient_id] = useState(null);
  const [department_id, setDepartment_id] = useState(null);

  const [HBxFirstName, setHBxFirstName] = useState(null);
  const [HBxLastName, setHBxLastName] = useState(null);
  const [HBxEmail, setHBxEmail] = useState(null);
  const [HBxDob, setHBxDob] = useState(null);
  const [HBxReasonForVisit, setHBxReasonForVisit] = useState(null);

  const [components, setComponents] = useState("landing");
  const [amount, setAmount] = useState(null);
  const [patientIdFromQP, setPatientIdFromQP] = useState(null);
  const [appointmentIdFromQP, setAppointmentIdFromQP] = useState(null);
  const [hashIdFromQP, setHashIdFromQP] = useState(null);
  const [providerTimeZone, setProviderTimeZone] = useState("America/New_York");
  const [allergyData, setAllergyData] = useState(null);
  const [medicationData, setMedicationData] = useState(null);
  const [allergyForm, setAllergyForm] = useState(null);
  const [medicationForm, setMedicationForm] = useState(null);

  const [deviceType, setDeviceType] = useState("laptop");
  const [patientTZ, setPatientTZ] = useState(null);

  const [loginData, setLoginData] = useState([
    {
      type: "email_phone",
      label: "Email or Phone Number",
      placeholder: "your email address or phone number",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid phone or email",
      validate: {
        required: true,
      },
    },
    {
      type: "password",
      label: "Password",
      placeholder: "at least seven characters",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid password",
      validate: {
        required: true,
      },
    },
    {
      type: "singleCheck",
      key: "tncCheck",
      label: [
        {
          text: "I agree to the",
        },
        {
          text: "Terms of Services",
          class: "form-title",
        },
        {
          text: "and",
        },
        {
          text: "Privacy Policy",
          class: "form-title",
        },
      ],
      // answer: [],
    },
  ]);
  const [userDetailsData, setUserDetailsData] = useState([
    {
      type: "textField",
      key: "firstName",
      label: "First Name",
      placeholder: "name",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid name",
      validate: {
        required: true,
      },
    },
    {
      type: "textField",
      key: "lastName",
      label: "Last Name",
      placeholder: "last name",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid last name",
      validate: {
        required: true,
      },
    },
    {
      type: "dob",
      label: "Date of Birth",
      placeholder: "MM/DD/YYYY",
      answer: { value: null },
      formValidation: true,
      invalidMessage: "Age should be 18+",
      validate: {
        required: true,
      },
    },
    {
      type: "textField",
      key: "email",
      label: "Email",
      placeholder: "email address",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid email",
      validate: {
        required: true,
      },
    },
    {
      type: "textField",
      key: "phone",
      label: "Phone Number",
      placeholder: "number",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid phone number",
      validate: {
        required: true,
      },
    },
    {
      type: "password",
      key: "password",
      label: "Password",
      placeholder: "at least seven characters",
      answer: { value: "" },
      formValidation: true,
      invalidMessage: "Please enter valid password",
      validate: {
        required: true,
      },
    },
  ]);
  const [appointmentData, setAppointmentData] = useState(null);
  const [appointmentSlotData, setAppointmentSlotData] = useState(null);
  const [socialKeys, setSocialKeys] = useState(null);
  const [paymentCollectedStatus, setPaymentCollectedStatus] = useState(null);
  const [insuranceCollectedStatus, setInsuranceCollectedStatus] =
    useState(null);

  return (
    <FormRendererContext.Provider
      value={{
        journeyData,
        setJourneyData,
        activeScreenID,
        setActiveScreenID,
        activeScreenPageID,
        setActiveScreenPageID,
        activeComponent,
        setActiveComponent,
        activeFormPage,
        setActiveFormPage,
        completeJourneyAnswers,
        setCompleteJourneyAnswers,
        schema,
        setSchema,
        activeScreen,
        setActiveScreen,
        activePage,
        setActivePage,
        answerData,
        setAnswerData,
        setJourneyId,
        journeyId,
        scheduler_id,
        setScheduler_id,
        provider_id,
        setProvider_id,
        reason_id,
        setReason_id,
        appointment_id,
        setAppointment_id,
        patient_id,
        setPatient_id,
        department_id,
        setDepartment_id,
        HBxFirstName,
        setHBxFirstName,
        HBxLastName,
        setHBxLastName,
        HBxEmail,
        setHBxEmail,
        HBxDob,
        setHBxDob,
        HBxReasonForVisit,
        setHBxReasonForVisit,
        components,
        setComponents,
        loginData,
        setLoginData,
        userDetailsData,
        setUserDetailsData,
        appointmentData,
        setAppointmentData,
        amount,
        setAmount,
        patientIdFromQP,
        setPatientIdFromQP,
        appointmentIdFromQP,
        setAppointmentIdFromQP,
        hashIdFromQP,
        setHashIdFromQP,
        providerTimeZone,
        setProviderTimeZone,
        allergyData,
        setAllergyData,
        medicationData,
        setMedicationData,
        appointmentSlotData,
        setAppointmentSlotData,
        deviceType,
        setDeviceType,
        patientTZ,
        setPatientTZ,
        socialKeys,
        setSocialKeys,
        insuranceCollectedStatus,
        setInsuranceCollectedStatus,
        paymentCollectedStatus,
        setPaymentCollectedStatus,
        allergyForm,
        setAllergyForm,
        medicationForm,
        setMedicationForm,
      }}
    >
      {children}
    </FormRendererContext.Provider>
  );
};

export default FormRendererContextProvider;
