import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";
import CustomTooltip from "./ToolTip";

const RadioSelector = ({ data, setAnswer, formValidation }) => {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
      setFormValidation(true);
    } else {
      setSelectedOption(undefined); // Clear selection if no data
    }

    if (answerData?.[data?.key]) {
      setSelectedOption(answerData?.[data?.key]?.value);
    }
  }, [formValidation, data]);

  return (
    <div className=" w-full">
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : ""}> * </span>
        ) : (
          <></>
        )}
        {data?.validate?.required && !validForm ? (
          <span className="text-red-700 font-light text-xs">
            {" "}
            Required Field{" "}
          </span>
        ) : (
          <></>
        )}
        {data?.tooltip && (
          <div className="inline text-center px-1 ">
            <CustomTooltip
              tooltipText={data.tooltip}
              width="500px"
            ></CustomTooltip>
          </div>
        )}
      </div>
      {data?.values && data?.values?.length ? (
        <>
          <div className="">
            {data.values.map((item) => {
              return (
                <div
                  key={item.value}
                  className="radio-common custom-radio-selector my-3 "
                >
                  <label className="pl-2">
                    <input
                      type="radio"
                      value={item.value}
                      checked={selectedOption === item.value}
                      className="mr-2 custom-radio"
                      id="box-shadow"
                      onClick={() => {
                        setSelectedOption(item.value);
                        setAnswer(data, item);
                        setFormValidation(true);
                        trackEvent(`radio ${data?.label} clicked`, {
                          question: data?.label,
                          questionKey: data?.key,
                          clickedAt: currentFormattedDateAndTime(),
                          data: "Answered"
                          // selectedValue: item.label,
                        });
                      }}
                    />
                    <span className="form-title">{item.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div></div>
      )}
      {data?.description && (
        <div className="text-xs text-gray-500 leading-relaxed mt-1">
          {data.description}
        </div>
      )}
    </div>
  );
};
export default RadioSelector;
