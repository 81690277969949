import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";

const TextField = ({ data, setAnswer, formValidation = true, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(
    data.answer?.value || ""
  );
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);
  const { usedFor } = data;

  const stateMapper = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    DC: "District of Columbia",
  };

  const handleFocus = (e) => {
    trackEvent(`text-field ${data?.label} clicked`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
    });
  };
  const handleBlur = (e) => {
    trackEvent(`text-field ${data?.label} filled`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
      // filledValue: selectedOption
      data: "Answered",
    });
  };

  const formatOutput = (outputText, usedFor) => {

    switch (usedFor) {
      case "card":
        // Format as XXXX XXXX XXXX XXXX (credit card format)
        return outputText
          .replace(/\D/g, "") // Remove non-numeric characters
          .replace(/(\d{4})(?=\d)/g, "$1 ") // Insert space after every 4 digits
          .substring(0, 19); // Limit to 7 characters (MM/YYYY)

      case "cardExpiry":
        // Format as MM/YYYY
        return outputText
          .replace(/\D/g, "") // Remove non-numeric characters
          .replace(/^(\d{2})(\d{0,4})/, (match, p1, p2) => {
            return p2 ? `${p1}/${p2}` : p1; // Add a slash after MM if there are digits for YYYY
          })
          .substring(0, 7); // Limit to 7 characters (MM/YYYY)

      default:
        // Default: Trim leading/trailing spaces
        return outputText.trim();
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
      setFormValidation(true);
    } else setSelectedOption("");

    if (answerData?.[data?.key]) {
      setSelectedOption(answerData?.[data?.key]?.value);
    }
  }, [data, formValidation]);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : "text-gray-600"}>
            {" "}
            *
          </span>
        ) : (
          <></>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common">
          <label className="relative">
            <input
              type="text"
              disabled={data?.disabled || false}
              value={
                data?.key === "demographicsState"
                  ? stateMapper?.[selectedOption] || selectedOption
                  : selectedOption
              }
              onChange={(e) => {
                let formattedValue = formatOutput(e.target.value, usedFor);
                setSelectedOption(formattedValue);
                setAnswer(data, { value: formattedValue });
                setFormValidation(true);
              }}
              onFocus={(e) => {
                handleFocus(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              placeholder={data?.suffix ? "" : data?.placeholder}
              className={
                data?.suffix
                  ? "text-field text-input text_white"
                  : "text-field text-input"
              }
            />
            {data?.suffix && (
              <div
                className="editable_div absolute w-full"
                onClick={() => {
                  var div = document.getElementById(data?.label + "editable");
                  div.focus();
                }}
              >
                <div
                  contenteditable="false"
                  className="editable_span inline-block pr-1"
                  id={data?.label + "editable"}
                >
                  {selectedOption}
                </div>
                <div
                  contenteditable="false"
                  className="non_editable_span inline-block"
                >
                  {data.suffix}
                </div>
              </div>
            )}
          </label>
        </div>
      </form>
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TextField;
