import { useState, useEffect, useRef, useContext } from "react";
import "./common.scss";
import Loader from "./loader";
import FormRendererContext from "../../services/context/FormRendererContext";
import { callAthenaGetApi } from "../../utils/athena";
import ContentRenderer from "./contentRenderer";

const DynamicContentRenderer = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
}) => {
  const [content, setContent] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  const { answerData, hashIdFromQP } = useContext(FormRendererContext);

  useEffect(() => {
    updateContent();
  }, [data]);

  const updateContent = async () => {
    setIsloading(true);
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getContentUrl = data?.fetchContentUrl;

    const paymentType =
      answerData?.selectHowSynaptrxSleepCanUseYourCardOnFile?.value ===
      "singleAppointment"
        ? "singleappointment"
        : answerData?.selectHowSynaptrxSleepCanUseYourCardOnFile?.value ===
          "oneYear"
        ? "oneyear"
        : "";

    if (paymentType) {
      const API_COMPLETE_URL = getContentUrl
        .replace("${API_URL}", API_URL)
        .replace("${hashIdFromQP}", hashIdFromQP)
        .replace("${appointmentType}", paymentType);
      const res = await callAthenaGetApi(API_COMPLETE_URL);

      const { HBx } = res.data;
      setContent(transformToHtml(HBx)?.contractTerms);
    }
    setIsloading(false)
  };

  const transformToHtml = (textObject) => {
    if (!textObject || typeof textObject !== "object") {
      throw new Error("Input must be an object containing plain text fields.");
    }

    // Transform each property in the object to HTML
    const transformedObject = {};
    for (const key in textObject) {
      if (textObject[key]) {
        transformedObject[key] = `
          <div style="font-family:Georgia, serif;">
            ${textObject[key]
              .replace(/(?:\r\n|\r|\n)/g, "<br>") // Replace newlines with <br>
              .replace(/^- (.+)$/gm, "<li>$1</li>") // Replace list items
              .replace(/(<li>.+<\/li>)/g, "<ul>$1</ul>")}
          </div>
        `.trim();
      }
    }

    return transformedObject;
  };

  const generateUniqueKey = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2);
  return (
    <>
      <div className={`w-full `}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ContentRenderer
              data={{ key: generateUniqueKey(), html: content, customClass: "text-gray-600 py-1" }}
            />
          </>
        )}
      </div>
    </>
  );
};
export default DynamicContentRenderer;
